import React from 'react';
import ReactMarkdown from 'react-markdown';

export default function Releaselog()
{

    const md = `
        # Code Name: ub.deltoid.client.1.9.3
        # Code Name: ub.deltoid.server.1.6.2

        ---
        ### NEW :
        ---
        - Multi-Party Video Call( 2 Moderator and 4 Participant )
        - Patient's Call Link now availble on Therapist Dashboard in "Call Details"   
        - Trial Session Feature added to patient before Assign Package  
        ---
        ### FIX :
        ---
        - Video Recording is Disabled 
        - Multiple Fixes
        ---
        `;
    return (
        <div className='h-screen flex justify-center items-center '>
            <ReactMarkdown children={md}>

            </ReactMarkdown>
        </div>
    );
}
